import React, { useState, useEffect } from 'react'
import { Modal, Tooltip, Spin } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import JsonPreview from '@core/components/JsonPreview'

import InformationApi from '../../../../../Information/api'
import style from '../index.module.scss'
import { UserPropTypes } from '../../UserPropTypes'
import { useUser } from '../../../../hooks/useUser'

const ProfileModal = ({ user }) => {
  const [open, setOpen] = useState(false)

  const showModal = () => {
    if (!user?.email) return
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={`View User profile${!user?.email ? ' | No user\'s email' : ''}`}>
        <UserOutlined onClick={showModal} className={!user?.email ? style.inactiveAction : style.activeAction} />
      </Tooltip>
      {open && <ProfileModalContent user={user} closeModal={closeModal} />}
    </>
  )
}

ProfileModal.propTypes = {
  user: UserPropTypes.isRequired,
}

const ProfileModalContent = ({ closeModal, user }) => {
  const [profile, setProfile] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { environment } = useUser()

  useEffect(() => {
    const init = async () => {
      setIsLoading(true)
      const result = await InformationApi.getTeaProfileTraveler(environment.toLowerCase(), { email: user.email })
      setProfile(result.data)
      setIsLoading(false)
    }

    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      title='View User Profile'
      visible
      onCancel={closeModal}
      footer={null}
      width={1000}
    >
      {isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}><Spin /></div>}
      {!isLoading && profile && <JsonPreview content={JSON.stringify(profile, null, 2)} autoSize />}
    </Modal>
  )
}

ProfileModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  user: UserPropTypes.isRequired,
}

export default ProfileModal
