import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ConfirmModal = ({ disabled, buttonTitle, modalTitle, onConfirm }) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const showModal = () => {
    setOpen(true)
  }
  const handleCancel = () => {
    setOpen(false)
  }
  const handleOk = async () => {
    setConfirmLoading(true)
    await onConfirm()
    setConfirmLoading(false)
    setOpen(false)
  }

  return (
    <>
      <Button disabled={disabled} type='primary' onClick={showModal}>
        {buttonTitle}
      </Button>
      {open && (
        <Modal
          width={400}
          title={modalTitle}
          visible
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          closable={!confirmLoading}
          bodyStyle={{ display: 'none' }}
          cancelButtonProps={{ disabled: confirmLoading }}
        />
      )}
    </>
  )
}

ConfirmModal.propTypes = {
  disabled: PropTypes.bool,
  buttonTitle: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

ConfirmModal.defaultProps = {
  disabled: false,
}

export default ConfirmModal
