import React, { useState, useContext, useEffect } from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Typography,
  Button,
  Table,
  Tooltip,
} from 'antd'
import { IssuesCloseOutlined } from '@ant-design/icons'
import useTimeoutAlert from '@core/hooks/useTimeoutAlert'
import useRequest from '@core/hooks/useRequest'

import { uniq } from '@core/helpers/array'

import Api from '../../../../../api'
import { EmailAutomationsContext } from '../../../../../context'
import { FormDirtyContext, EmailItemsUpdaterContext } from '../../context'

import generateTableDatasource from './generateTableDatasource'
import {
  mapFilterSubmittedIntent,
  checkIfWasSubmittedPositive,
  checkIfWasSubmittedNegative,
  checkIfOppositeWasSubmitted,
  findIdOfIntentByName,
  findSubmittedIntentTagging,
} from './intentDataLookup'

import ClickableIntentCellContent from './ClickableIntentCellContent'

import SubmissionLegends from './SubmissionLegends'
import IntentsSelectionActions from './IntentsSelectionActions'
import ApprovalBulkActions from './ApprovalBulkActions'
import ApprovalActions from './ApprovalActions'
import SubmissionFeedbacks from './SubmissionFeedbacks'

import style from './style.module.scss'

// FIXME: We might not need emailId, emailBody; probably use context
// FIXME: Too large...
const IntentsList = ({ emailId, emailBody }) => {
  const { versions: { currentVersion } = {}, datasource } = useContext(EmailAutomationsContext)
  const { updateSpecificEmailIntentSamples } = useContext(EmailItemsUpdaterContext)
  const { setIsFormDirty } = useContext(FormDirtyContext)

  const [selectedIntent, setSelectedIntent] = useState([])
  const [isMakingRequest, setIsMakingRequest] = useState(false)

  const [intentApprovalError, setIntentApprovalError] = useState(null)
  const [intentApprovalSuccess, setIntentApprovalSuccess] = useState(null)

  const [showSubmittedFeedback, setShowSubmittedFeedback] = useTimeoutAlert(null)
  const [showSubmissionError, setShowSubmissionError] = useTimeoutAlert(null)
  const [showTaggingDeletedFeedback, setShowTaggingDeletedFeedback] = useTimeoutAlert(null)
  const [showDeletedError, setShowDeletedError] = useTimeoutAlert(null)

  const { shouldShowActionsColumn, canAddNewIntentTagging, canApprove, canReject } = useSelector(({ core }) => {
    const { permittedFeatures: { authorization } } = core
    const { transformed: { emailautomations } } = authorization

    return {
      canAddNewIntentTagging: emailautomations.intentTrainingData.actionAdd,
      canApprove: emailautomations.intentTrainingData.actionApprove,
      canReject: emailautomations.intentTrainingData.actionReject,
      // If a user does not have permission to Delete/Approve/Reject, the whole column should not displayed.
      // well, it means...if some of them are true then show
      shouldShowActionsColumn: [
        emailautomations.intentTrainingData.actionApprove,
        emailautomations.intentTrainingData.actionDelete,
        emailautomations.intentTrainingData.actionReject,
      ].some((item) => { return item === true }),
    }
  })

  const dataSourceQueryParams = { version: currentVersion, datasource }
  const [{
    loading: intentsLoading,
    data: intentEndpointResponse,
  }] = useRequest(Api.Intents.getIntents, dataSourceQueryParams)
  const [
    {
      loading: predictionLoading,
      data: predictionResults,
      hasError: predictionHasError,
    },
    { makeRequest: retryPrediction },
  ] = useRequest(Api.Intents.getRecognizedIntentsByText, emailBody, dataSourceQueryParams)
  const [
    {
      loading: currentEmailSampleLoading,
      data: samples,
    },
    { silentMakeRequest: getTrainingCases },
  ] = useRequest(Api.Emails.getSamples, dataSourceQueryParams, emailId)

  const predictionSimplifiedScores = !predictionResults?.intents ? [] : predictionResults?.intents.map((predictedIntent) => {
    if (predictedIntent.score === 1) {
      return {
        name: predictedIntent.name,
        rawscore: 100,
        twodigit: '100',
        fourdigit: '100',
      }
    }

    const adjustedScore = predictedIntent.score * 100

    return {
      name: predictedIntent.name,
      rawscore: adjustedScore,
      twodigit: adjustedScore.toFixed(2),
      fourdigit: adjustedScore.toFixed(4),
    }
  })

  const shouldDisableAllApprovalButtonAction = samples ? samples.every((intent) => { return intent.approval_status !== 'SUBMITTED' }) : true
  const allIntentsHasBeenSelected = samples?.length === Object.keys(intentEndpointResponse?.intentPairs ?? {}).length

  // as i remember Yas told me it will not recognized negative intents
  const applyPredictionsToSubmission = () => {
    // NOTE: For some reason eslint 'does not like' how optional chaining works with deeply nested
    // and causing string template broke
    // it is like it's trying to 'execute' code instead just linting
    // the workaround is check the value exists or not
    // probably it does not like with jsx mixed with es6 syntax
    if (predictionResults && predictionResults.intents) {
      setIsFormDirty(true)

      const intentsFromPrediction = predictionResults.intents.map((item) => {
        return item.name
      })
      const negativeIntentsPredictionToRemove = predictionResults.intents.map((item) => {
        return `not-${item.name}`
      })

      // remove negative from currently selected
      const newSelectedIntent = selectedIntent.filter((intent) => {
        return negativeIntentsPredictionToRemove.indexOf(intent) === -1
      })
      const nextState = [...intentsFromPrediction, ...newSelectedIntent]

      setSelectedIntent(nextState)
    }
  }

  const addUnselectedIntentToNegativeForSubmission = () => {
    const negativeIntents = !intentEndpointResponse
      ? []
      : Object.values(intentEndpointResponse?.intentPairs).map((intent) => {
        const negativeIntent = intent.negative
        return negativeIntent.name
      })

    // or make any positive intent inside submittedIntent prefixes 'not-'
    const submittedIntent = samples.map((sample) => { return sample.name })
    const selectedPositiveIntents = selectedIntent.filter((intent) => {
      return !intent.startsWith('not-')
    })
    const submittedPositiveIntents = samples.reduce((arr, sample) => {
      if (!sample.name.startsWith('not-')) arr.push(sample.name)
      return arr
    }, [])

    setSelectedIntent((prev) => {
      return uniq([
        ...prev,
        // FIXME: what the fuck with these chaining
        ...negativeIntents.filter((intent) => {
          // this one make sure the negative that will be added
          // was not already selected on positive side (react state)
          return selectedPositiveIntents.indexOf(intent.slice(4)) === -1
        }).filter((intent) => {
          // this one make sure the one that was submitted as
          // positive would mark its opposite as negative
          return submittedPositiveIntents.indexOf(intent.slice(4)) === -1
        }).filter((intent) => {
          // this one last check on submitted negative
          return !submittedIntent.includes(intent)
        }),
      ])
    })
  }

  const addIntentToSubmission = (record, polarityFrom) => {
    const newIntentName = record[polarityFrom]

    return (event) => {
      event.stopPropagation()

      setIsFormDirty(true)
      setSelectedIntent((prev) => {
        const existIndex = prev.indexOf(newIntentName)
        let oppositeIntentNameToTakeout

        if (existIndex !== -1) {
          return prev.filter((prevIntentNameItem) => {
            return prevIntentNameItem !== newIntentName
          })
        }

        if (polarityFrom === 'negative') {
          oppositeIntentNameToTakeout = newIntentName.slice(4) // not- is 4 length anyway....
        }

        // what the fuck
        if (polarityFrom === 'positive') {
          oppositeIntentNameToTakeout = `not-${newIntentName}`
        }

        const removeOppositeAlready = prev.filter((item) => { return item !== oppositeIntentNameToTakeout })
        return uniq([...removeOppositeAlready, newIntentName])
      })
    }
  }

  // batch delete doesn't update status to IN_PROGRESS
  const deleteIntentTagging = async (intent) => {
    setIsMakingRequest(true)
    setShowTaggingDeletedFeedback(null)

    try {
      const response = await Api.Trainings.deleteSample({
        version: currentVersion,
        emailId,
        intentId: intent.id,
        datasource,
      })

      if (response?.status === 'success') {
        const updatedAssignedIntents = await getTrainingCases() // this returns updated one, we can use this response
        if (updatedAssignedIntents) updateSpecificEmailIntentSamples(emailId, updatedAssignedIntents)
      }
    } catch (error) {
      console.error('TrainingCases#deleteIntentTagging - error - ', error)
      setShowDeletedError({ intentName: intent.name })
      console.error(error)
    }

    setIsMakingRequest(false)
    setShowTaggingDeletedFeedback({ intentName: intent.name })
  }

  const approveIntents = async (intentIds) => {
    setIsMakingRequest(true)
    setIntentApprovalError(null)
    setIntentApprovalSuccess(null)

    try {
      const response = await Api.Trainings.batchApprove({
        version: currentVersion,
        emailId,
        intentIds,
        datasource,
      })

      if (response?.status === 'success') {
        await getTrainingCases()
        setIntentApprovalSuccess({ type: 'approve' })
      }
    } catch (error) {
      console.error('requestFn - error - ', error)
      setIntentApprovalError({ type: 'approve' })
    }

    setIsMakingRequest(false)
  }

  const rejectIntents = async (intentIds) => {
    setIsMakingRequest(true)
    setIntentApprovalError(null)
    setIntentApprovalSuccess(null)

    try {
      const response = await Api.Trainings.batchReject({
        version: currentVersion,
        emailId,
        intentIds,
        datasource,
      })

      if (response?.status === 'success') {
        await getTrainingCases()
        setIntentApprovalSuccess({ type: 'reject' })
      }
    } catch (error) {
      console.error('requestFn - error - ', error)
      setIntentApprovalError({ type: 'reject' })
    }

    setIsMakingRequest(false)
  }

  const approveIntentTagging = (intent) => {
    approveIntents([intent.id])
  }

  const rejectIntentTagging = (intent) => {
    rejectIntents([intent.id])
  }

  const approveAllIntentTagging = () => {
    approveIntents(mapFilterSubmittedIntent(samples))
  }

  const rejectAllIntentTagging = () => {
    rejectIntents(mapFilterSubmittedIntent(samples))
  }

  /*
    Badge thing
    ===========
    It was meant to replace Analyze/Classification table where it gives 'comparison' functionality if there are more than one classification.
    Badge will ALWAYS show, per its intent that got classified

    Badge will be clickable, first state will show two-digits decimal, once click, it goes full decimal
  */

  // table
  const columns = [
    {
      title: () => { return <Typography.Text strong>Intents</Typography.Text> },
      children: [
        {
          title: (
            <Tooltip title='Submission Status'>
              <Typography.Text className={style.SubmissionIcons}>
                <IssuesCloseOutlined />
              </Typography.Text>
            </Tooltip>
          ),
          fixed: 'left',
          width: 86,
          render: (record) => {
            const emojiMap = {
              SUBMITTED: '⏱',
              APPROVED: '✅',
              REJECTED: '❌',
            }

            const status = findSubmittedIntentTagging(record, samples)

            return (
              <Typography.Text className={style.SubmissionIcons}>
                {emojiMap[status?.approval_status] ?? '？'}
              </Typography.Text>
            )
          },
        },
        {
          title: (<Typography.Text>Positive</Typography.Text>),
          fixed: 'left',
          render: (record) => {
            const { positive: intentName } = record
            return (
              <ClickableIntentCellContent
                intentName={intentName}
                onClick={canAddNewIntentTagging ? addIntentToSubmission(record, 'positive') : () => { }}
                matchedPrediction={predictionSimplifiedScores.find((item) => {
                  return item.name === intentName
                })}
                selected={selectedIntent.indexOf(intentName) !== -1}
                polarity='positive'
                wasSubmitted={checkIfWasSubmittedPositive(intentName, samples)}
                oppositeSelected={checkIfOppositeWasSubmitted(intentName, samples)}
              />
            )
          },
        },
        {
          title: canAddNewIntentTagging && !allIntentsHasBeenSelected
            ? (
              <Typography.Link onClick={addUnselectedIntentToNegativeForSubmission}>
                Negative
              </Typography.Link>
            )
            : (
              <Typography.Text>Negative</Typography.Text>
            ),
          fixed: 'left',
          render: (record) => {
            const { negative: intentName } = record
            return (
              <ClickableIntentCellContent
                intentName={intentName}
                onClick={canAddNewIntentTagging ? addIntentToSubmission(record, 'negative') : () => { }}
                matchedPrediction={predictionSimplifiedScores.find((item) => {
                  return item.name === intentName
                })}
                selected={selectedIntent.indexOf(intentName) !== -1}
                polarity='negative'
                wasSubmitted={checkIfWasSubmittedNegative(intentName, samples)}
                oppositeSelected={checkIfOppositeWasSubmitted(intentName, samples)}
              />
            )
          },
        },
      ],
      fixed: 'left',
    },
    {
      title: () => { return <Typography.Text strong>Actions</Typography.Text> },
      fixed: true,
      width: 100,
      render: (record) => {
        if (!shouldShowActionsColumn) return null

        const intentSubmission = findSubmittedIntentTagging(record, samples)

        // if there is no status, don't render
        // - if the status is 'UNAVAILABLE' DO NOT show buttons, this is EDGE CASE! should not ever happen
        if (!intentSubmission || intentSubmission.approval_status === 'UNAVAILABLE') return null
        if (!intentSubmission.approval_status) return (<Typography.Text>N/A</Typography.Text>)

        // - when it is approved/rejected, those two buttons should be disabled
        const alreadyChecked = ['APPROVED', 'REJECTED'].indexOf(intentSubmission.approval_status) !== -1
        const deleteTraining = (intent) => {
          return () => {
            deleteIntentTagging(intent)
          }
        }

        const approveTraining = (intent) => {
          return () => {
            approveIntentTagging(intent)
          }
        }

        const rejectTraining = (intent) => {
          return () => {
            rejectIntentTagging(intent)
          }
        }

        return (
          <ApprovalActions
            deleteTraining={deleteTraining(intentSubmission)}
            approveTraining={alreadyChecked ? () => { } : approveTraining(intentSubmission)}
            rejectTraining={alreadyChecked ? () => { } : rejectTraining(intentSubmission)}
            alreadyChecked={alreadyChecked}
            isLoading={isMakingRequest}
          />
        )
      },
    },
  ]

  const clearSelection = () => {
    setSelectedIntent([])
  }

  const saveChanges = async () => {
    setIsMakingRequest(true)

    try {
      const response = await Api.Trainings.batchUpsert({
        version: currentVersion,
        intentIds: selectedIntent.map((intent) => {
          return findIdOfIntentByName(intent, intentEndpointResponse.intentPairs, intentEndpointResponse.specificIntentPairs)
        }),
        emailItemIds: [emailId],
        datasource,
      })

      if (response?.status === 'success') {
        const updatedAssignedIntents = await getTrainingCases() // this returns updated one, we can use this response
        if (updatedAssignedIntents) updateSpecificEmailIntentSamples(emailId, updatedAssignedIntents)
        setSelectedIntent([])
        setShowSubmittedFeedback(true)
      }
    } catch (error) {
      console.error('TrainingCases#onFinish - error - ', error)
      setShowSubmissionError({
        intentNames: selectedIntent.reduce((acc, intentName, index, array) => {
          return `${acc} ${intentName}${index < array.length - 1 ? ',' : ''}`
        }, ''),
      })
      console.error('TrainingCases#onFinish - error during submit training intents')
      console.error(error)
    }

    setIsFormDirty(false)
    setIsMakingRequest(false)
  }

  // NOTE: FIXME: Too many calls for getTrainingCases
  // but, each request make sure it gets correct response from backend
  // so it doesn't assumed all intents are succeed
  const saveAndApproveAll = async () => {
    setIsMakingRequest(true)
    let saveResponse
    let approveAllResponse

    try {
      saveResponse = await Api.Trainings.batchUpsert({
        version: currentVersion,
        intentIds: selectedIntent.map((intent) => {
          return findIdOfIntentByName(intent, intentEndpointResponse.intentPairs)
        }),
        emailItemIds: [emailId],
        datasource,
      })
    } catch (error) {
      console.error('saveAndApproveAll - error during batchUpsert')
      setShowSubmissionError({
        intentNames: selectedIntent.reduce((acc, intentName, index, array) => {
          return `${acc} ${intentName}${index < array.length - 1 ? ',' : ''}`
        }, ''),
      })
      console.error(error)
    }

    if (saveResponse?.status === 'success') {
      const updatedAssignedIntents = await getTrainingCases()
      if (updatedAssignedIntents) updateSpecificEmailIntentSamples(emailId, updatedAssignedIntents)

      try {
        approveAllResponse = await Api.Trainings.batchApprove({
          version: currentVersion,
          emailId,
          intentIds: selectedIntent.map((intent) => {
            return findIdOfIntentByName(intent, intentEndpointResponse.intentPairs)
          }),
          datasource,
        })
      } catch (error) {
        console.error('saveAndApproveAll - error during batchApprove')
        setIntentApprovalError({ type: 'approve' })
        console.error(error)
      }
    }

    if (approveAllResponse?.status === 'success') {
      await getTrainingCases() // final sync
      setIntentApprovalSuccess({ type: 'approve' })
    }

    setIsFormDirty(false)
    setIsMakingRequest(false)
  }

  const getRowClassname = (record) => {
    const classes = []

    const status = findSubmittedIntentTagging(record, samples)

    if (status?.name) {
      if (status?.name.startsWith('not-')) {
        classes.push(style.NegativeSelected)
      } else {
        classes.push(style.PositiveSelected)
      }
    }

    if (status?.approval_status) {
      classes.push(style[status.approval_status])
      classes.push(style.RowIsSubmitted)
    }

    if (selectedIntent.indexOf(record.positive) !== -1 || selectedIntent.indexOf(record.negative) !== -1) {
      classes.push(style.RowIsSelected)

      if (selectedIntent.indexOf(record.positive) !== -1) classes.push(style.PositiveSelected)
      if (selectedIntent.indexOf(record.negative) !== -1) classes.push(style.NegativeSelected)
    }

    if (record.level === 2) {
      classes.push(style.LevelTwoIntent)
    }

    return classnames(...classes)
  }

  const getBatchApprovalLoadingState = () => {
    if (currentEmailSampleLoading || isMakingRequest || shouldDisableAllApprovalButtonAction) {
      return true
    }

    return false
  }

  const getTableLoadingState = () => {
    // technically it is <Spin /> props
    if (intentsLoading || isMakingRequest) return true

    if (currentEmailSampleLoading) {
      return {
        spinning: true,
        tip: 'Sample is loading',
      }
    }

    // approval spinner goes here

    return false
  }

  const getApplyPredictionLoadingState = () => {
    const predictionResultsHasNoIntents = !predictionResults?.intents.length

    if (
      (predictionResultsHasNoIntents && !predictionHasError)
      || (predictionResultsHasNoIntents && predictionHasError)
      || isMakingRequest
    ) {
      return true
    }

    return false
  }

  const getApplyPredictionButtonText = () => {
    if (predictionLoading) return 'Prediction is predicting'
    if (predictionHasError) return 'Error retrieving prediction, try again?'
    if (!predictionResults?.intents.length) return 'No intent matched'

    return 'Apply prediction to test case'
  }

  useEffect(() => {
    setSelectedIntent([])
  }, [emailId])

  return (
    <>
      <div className={style.IntentTrainingCaseTitle}>
        <Button
          loading={predictionLoading}
          type='primary'
          onClick={predictionHasError ? retryPrediction : applyPredictionsToSubmission}
          disabled={getApplyPredictionLoadingState()}
        >
          {getApplyPredictionButtonText()}
        </Button>
        {(canApprove || canReject) && (
          <ApprovalBulkActions
            isBatchApprovalButtonDisabled={getBatchApprovalLoadingState()}
            approveAllIntentTagging={approveAllIntentTagging}
            rejectAllIntentTagging={rejectAllIntentTagging}
          />
        )}
      </div>
      <Table
        bordered
        rowKey='id'
        size='small'
        loading={getTableLoadingState()}
        columns={columns}
        rowClassName={getRowClassname}
        dataSource={generateTableDatasource(intentEndpointResponse)}
        pagination={false} // required: no pagination from Rob
      />
      <SubmissionLegends />
      {
        canAddNewIntentTagging
        && (
          <IntentsSelectionActions
            saveAndApproveAll={saveAndApproveAll}
            saveChanges={saveChanges}
            addUnselectedIntentToNegativeForSubmission={addUnselectedIntentToNegativeForSubmission}
            clearSelection={clearSelection}
            saveAndApproveAllDisabled={isMakingRequest || !selectedIntent.length}
            saveChangesDisabled={isMakingRequest || !selectedIntent.length}
            allIntentsHasBeenSelected={allIntentsHasBeenSelected}
            setRemainingNegativeDisabled={allIntentsHasBeenSelected || intentsLoading || isMakingRequest}
            resetDisabled={intentsLoading || isMakingRequest || !selectedIntent.length}
          />
        )
      }
      <SubmissionFeedbacks
        intentApprovalError={intentApprovalError}
        intentApprovalSuccess={intentApprovalSuccess}
        showDeletedError={showDeletedError}
        showTaggingDeletedFeedback={showTaggingDeletedFeedback}
        showSubmissionError={showSubmissionError}
        showSubmittedFeedback={showSubmittedFeedback}
      />
    </>
  )
}

IntentsList.propTypes = {
  emailId: PropTypes.string.isRequired,
  emailBody: PropTypes.string.isRequired,
}

export default IntentsList
