import { createContext, useContext } from 'react'

export const UserContext = createContext({
  environment: 'PRODUCTION',
  setEnvironment: () => { },
  dataSource: [],
  setDataSource: () => { },
  loadDataSource: async () => { },
  isLoading: false,
  form: undefined,
  getFields: () => { },
  isInit: true,
})

export const useUser = () => { return useContext(UserContext) }
