import React from 'react'
import { Table, Space, Typography, Tooltip, Tag, Spin } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { sortTextOrNull } from '@core/helpers'
import { DB_CHANNEL_MAP } from '@core/constants/channels'
import { useUser } from '../../../hooks/useUser'
import InvestigateModal from './InvestigateModal'
import ProfileModal from './ProfileModal'
import ViewPTCAction from './ViewPTCAction'
import ViewConversationAction from './ViewConversationAction'
import ViewLogAction from './ViewLogAction'
import RawModal from './RawModal'

const UserListContent = () => {
  const { isLoading, dataSource, isInit } = useUser()
  const columns = [
    {
      title: (
        <Typography.Text strong>
          Email
          {' '}
          <Tooltip title='Email address or Channel User ID when email address is missing.'><InfoCircleOutlined /></Tooltip>
        </Typography.Text>
      ),
      dataIndex: 'email',
      width: 250,
      sorter: (a, b) => { return sortTextOrNull(a.email, b.email) },
      defaultSortOrder: 'ascend',
      render: (_, { email, channelUserId }) => {
        const displayEmail = email ?? channelUserId
        let start = displayEmail
        let suffix = ''
        if (displayEmail.length > 40) {
          start = displayEmail.slice(0, 22)
          suffix = `...${displayEmail.slice(-18)}`
        }

        return (
          <Space size={10}>
            <Tooltip title={displayEmail}>
              <Typography.Text copyable={{ text: displayEmail }} ellipsis={{ suffix }}>
                {!email && '(!) '}
                {start}
              </Typography.Text>
            </Tooltip>
          </Space>
        )
      },
    },
    {
      title: (<Typography.Text strong>Channel</Typography.Text>),
      dataIndex: 'fromChannel',
      key: 'fromChannel',
      sorter: (a, b) => { return sortTextOrNull(a.fromChannel, b.fromChannel) },
      width: 100,
      render: (fromChannel) => {
        return DB_CHANNEL_MAP[fromChannel]
      },
    },
    {
      title: (<Typography.Text strong>Pending Steps</Typography.Text>),
      dataIndex: 'pendingSteps',
      width: 200,
      render: (_, { onAuthentication, onConversation, onCSAT, onPTC }) => {
        const pendingSteps = []
        if (onAuthentication?.status) {
          pendingSteps.push('Authenticate')
        }
        if (onConversation?.status) {
          pendingSteps.push('Conversation')
        }
        if (onCSAT?.status) {
          pendingSteps.push('CSAT')
        }
        if (onPTC?.status) {
          pendingSteps.push('PTC')
        }

        return <>{pendingSteps.map((step) => { return <Tag key={step}>{step}</Tag> })}</>
      },
    },
    {
      title: (<Typography.Text strong>Actions</Typography.Text>),
      width: 180,
      render: (_, user) => {
        return (
          <Space size='middle'>
            <InvestigateModal user={user} />
            <ProfileModal user={user} />
            <ViewPTCAction conversationId={user?.onConversation?.data?.conversationId} channel={user?.fromChannel} />
            <ViewConversationAction conversationId={user?.onConversation?.data?.conversationId} />
            <ViewLogAction channel={user?.fromChannel} />
            <RawModal user={user} />
          </Space>
        )
      },
    },
  ]

  if (isInit) {
    return (
      <div style={{ textAlign: 'center', marginTop: '200px' }}>
        <Typography.Text type='secondary'>
          Please use the filters in the left to query users.
        </Typography.Text>
      </div>
    )
  }

  return isLoading
    ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}><Spin /></div>
    : (
      <Table
        columns={columns}
        dataSource={dataSource}
        size='small'
        pagination={false}
        rowKey='userId'
        scroll={{
          scrollToFirstRowOnChange: true,
          y: 675,
        }}
      />
    )
}

export default UserListContent
