import React from 'react'
import { Link } from 'react-router-dom'
import { CommentOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import style from './index.module.scss'
import { useUser } from '../../../hooks/useUser'

const ViewConversationAction = ({ conversationId }) => {
  const { environment } = useUser()

  const conversationPageSearchParams = new URLSearchParams()
  const filter = {
    datasource: environment.toLowerCase(),
    conversation_ids: [conversationId],
    texts: [],
    exclude_texts: [],
    regexes: [],
    exclude_regexes: [],
    message_type: 'ANY',
    exclude_from_training: -1,
    tags: [],
    bot_relevance_type: 'ANY',
    le_conversation_ids: [],
    page: 1,
  }
  conversationPageSearchParams.set('filter', JSON.stringify(filter))
  const conversationPageUrl = `/conversations/query?${conversationPageSearchParams.toString()}`

  return (
    <Tooltip title='View conversation'>
      <Link to={conversationPageUrl}>
        <CommentOutlined className={style.activeAction} />
      </Link>
    </Tooltip>
  )
}

ViewConversationAction.propTypes = {
  conversationId: PropTypes.string,
}

ViewConversationAction.defaultProps = {
  conversationId: '',
}

export default ViewConversationAction
