import api from '../../../../../api'

const getJobStatus = async (jobId, datasource) => {
  return new Promise((resolve) => {
    let isRunning = false
    const polling = setInterval(async () => {
      if (isRunning) { return }
      isRunning = true
      try {
        const { data: jobData } = await api.User.getJobStatus(jobId, { datasource })
        if (jobData?.jobStatus !== 'IN_PROGRESS') {
          clearInterval(polling)
          resolve(jobData.jobStatus)
        }
      } catch (error) {
        clearInterval(polling)
        resolve('Fail to polling job')
      }
      isRunning = false
    }, 1000)
  })
}

export default getJobStatus
