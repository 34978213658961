import React, { useEffect, useState, useRef } from 'react'
import {
  Layout,
  Typography,
  Tabs,
  Space,
  Tag,
  Empty,
  Radio,
  Spin,
  Modal,
  BackTop,
  notification,
  Button,
  Row,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import PermittedFeatureCheck from '@core/components/PermittedFeatureCheck'
import useActiveHashTab from '@core/hooks/useActiveHashTab'
import useRequest from '@core/hooks/useRequest'
import StorageUtils from '@core/utils/storage'

import { BulkModeStorageInterface } from '../helpers'
import { useEmailSearchResults } from '../hooks/useEmailSearchResults'
import { usePermittedEmailAutomationFeatures } from '../hooks/usePermittedEmailAutomationFeatures'
import { EmailAutomationsContext } from '../context'

import AnalyzeEmailResponseModal from '../components/Emails/AnalyzeEmailResponseModal'

import MainEmailAutomationTabPages from './MainEmailAutomationTabPages'

import Api from '../api'
import style from './style.module.scss'

// maybe version from url query
// then that link would be sharable

const getDatasourceAndVersionFromSessionStorage = () => {
  return JSON.parse(StorageUtils.getItem('email_datasource_version'))
}

const getEmailAutomationDatasourceTargets = (versionSelectionPermissionList) => {
  const emailAutomationDatasourceTargets = []

  // canary can't request production version anyway
  if (process.env.REACT_APP_ENV !== 'canary' && versionSelectionPermissionList.actionSelectProduction) {
    emailAutomationDatasourceTargets.push('production')
  }
  if (versionSelectionPermissionList.actionSelectStaging) {
    emailAutomationDatasourceTargets.push('staging')
  }
  if (versionSelectionPermissionList.actionSelectCanary) {
    emailAutomationDatasourceTargets.push('canary')
  }
  if (process.env.REACT_APP_ENV === 'development') {
    emailAutomationDatasourceTargets.push('development')
  }

  return emailAutomationDatasourceTargets
}

const EmailAutomationHome = () => {
  const { emailautomations: emailAutomationsPermissions } = usePermittedEmailAutomationFeatures()
  const emailAutomationDatasourceTargets = getEmailAutomationDatasourceTargets(emailAutomationsPermissions?.versions)
  const sessionStorageValue = getDatasourceAndVersionFromSessionStorage()

  const [activeKey, setActiveKey] = useActiveHashTab('versions')
  const emailSearchResults = useEmailSearchResults()

  const analyzeModalRef = useRef(null)

  // assign from session if it's exist
  const [datasource, setDatasource] = useState(() => {
    if (sessionStorageValue?.datasource) return sessionStorageValue.datasource

    const { REACT_APP_DEV_MODE, REACT_APP_ENV } = process.env

    if (REACT_APP_DEV_MODE) {
      switch (REACT_APP_ENV) {
        case 'development': return 'development'
        case 'canary': return 'canary'
        case 'staging': return 'staging'

        default: return 'staging'
      }
    }

    return 'production'
  })
  const [selectedWorkingVersion, setSelectedWorkingVersion] = useState(() => {
    if (sessionStorageValue?.version) return sessionStorageValue.version

    return null
  })

  const [isAnalyzeEmailModalOpen, setIsAnalyzeEmailModalOpen] = useState(false)

  const [versionState, { makeRequest: refreshVersions }] = useRequest(Api.Versions.getVersions, { datasource })

  const closeAnalyzeEmailModal = () => {
    setIsAnalyzeEmailModalOpen(false)
  }

  const openAnalyzeEmailModal = () => {
    setIsAnalyzeEmailModalOpen(true)
  }

  const clickToAnalyzeEmail = async ({ body: bodyToSetForm }) => {
    setIsAnalyzeEmailModalOpen(true)
    if (analyzeModalRef.current) analyzeModalRef.current.setBodyAndCountryOfEmployment({ body: bodyToSetForm })
  }

  const onSelectEmailAutomationDatasource = (event) => {
    const { target: { value } } = event

    Modal.confirm({
      title: (
        <Typography.Text>
          This will change the current datasource to:
          {' '}
          <strong>{value.charAt(0).toUpperCase() + value.substring(1, value.length)}</strong>
        </Typography.Text>
      ),
      icon: <ExclamationCircleOutlined />,
      content: 'This will discard all unsaved changes',
      onOk: async () => {
        try {
          setDatasource(value)
          setSelectedWorkingVersion(null)

          // Clear storage when user moved to other datasource,
          // preserving old behavior when user moving to different datasource
          StorageUtils.removeItem('email_datasource_version')
        } catch (error) {
          notification.error({
            message: 'Error',
            description: error.message,
            duration: 5,
          })
        }
      },
      onCancel: () => { },
    })
  }

  const renderSelectWorkingEnvironmentOptions = () => {
    return (
      <Space>
        <Typography.Text strong>Select environment:</Typography.Text>
        <Radio.Group
          size='small'
          onChange={onSelectEmailAutomationDatasource}
          value={datasource}
        >
          {emailAutomationDatasourceTargets.map((item, index) => {
            return (
              <Radio.Button key={index} value={item}>
                {item.charAt(0).toUpperCase() + item.substring(1, item.length)}
              </Radio.Button>
            )
          })}
        </Radio.Group>
      </Space>
    )
  }

  const getTabTitleNode = (tabKey, title) => {
    return (
      <Typography.Text strong={activeKey === tabKey} style={{ color: '#1890ff' }}>{title}</Typography.Text>
    )
  }

  const renderCheckIsWorkingVersionWasSelected = () => {
    if (!selectedWorkingVersion) {
      return (
        <Typography.Text type='danger' strong>
          <sup>*</sup>
          You must choose version before proceeding further
        </Typography.Text>
      )
    }

    return (
      <div>
        <Tag color='orange'>
          Selected Work Environments:
          {' '}
          <strong>{datasource}</strong>
        </Tag>
        <Tag color='green'>
          Selected Working Version:
          {' '}
          <strong>{selectedWorkingVersion}</strong>
        </Tag>
      </div>
    )
  }

  useEffect(() => {
    if (!selectedWorkingVersion) setActiveKey('versions')
  }, [selectedWorkingVersion, setActiveKey])

  // NOTE: Do we need to cleared saved version here when moved to other pages?

  if (versionState.loading) {
    return (
      <Layout.Content className={style.Pending}>
        <Spin />
      </Layout.Content>
    )
  }

  if (!versionState.data) {
    return (
      <Layout.Content>
        <Space align='baseline'>
          <Typography.Title level={4}>Email Automations</Typography.Title>
          {renderCheckIsWorkingVersionWasSelected()}
        </Space>
        {/* Unnecessary, needs to adjust how spinner render, but keeps look and feels same and consistent, for now  */}
        <Space className={style.EmptyResult} direction='vertical'>
          <Tabs activeKey={activeKey} onChange={setActiveKey} type='card' destroyInactiveTabPane>
            <Tabs.TabPane key='versions' tab={getTabTitleNode('versions', 'Versions')}>
              <Space className={style.EmptyResult} direction='vertical'>
                {renderSelectWorkingEnvironmentOptions()}
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No result(s)' />
              </Space>
            </Tabs.TabPane>
          </Tabs>
        </Space>
      </Layout.Content>
    )
  }

  return (
    <Layout.Content>
      <Row>
        <Space align='baseline'>
          <Typography.Title level={4}>Email Automations</Typography.Title>
          {renderCheckIsWorkingVersionWasSelected()}
        </Space>
        <PermittedFeatureCheck featurePath='email.analysis.viewTab'>
          <Button
            className={style.EmailAnalyzeButton}
            onClick={openAnalyzeEmailModal}
            disabled={!selectedWorkingVersion}
            type='primary'
          >
            Analyze Email content
          </Button>
        </PermittedFeatureCheck>
      </Row>
      <EmailAutomationsContext.Provider
        value={{
          bulkModeChangeHistory: datasource ? BulkModeStorageInterface.retrieveRecentlyChanged()[datasource] : null,
          emailSearchResults, // can be load inside, this needs to be here, needs to be persistent, or at least it refresh when come back, we need to keep params
          versions: {
            ...versionState,
            currentVersion: selectedWorkingVersion,
          },
          selectedWorkingVersion,
          setSelectedWorkingVersion,
          refreshVersions,
          datasource,
          permissions: emailAutomationsPermissions,
        }}
      >
        <MainEmailAutomationTabPages
          selectedWorkingVersion={selectedWorkingVersion}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          getTabTitleNode={getTabTitleNode}
          renderSelectWorkingEnvironmentOptions={renderSelectWorkingEnvironmentOptions}
          clickToAnalyzeEmail={clickToAnalyzeEmail}
        />
        <PermittedFeatureCheck featurePath='email.analysis.viewTab'>
          <AnalyzeEmailResponseModal
            ref={analyzeModalRef}
            visible={isAnalyzeEmailModalOpen}
            onCancel={closeAnalyzeEmailModal}
          />
        </PermittedFeatureCheck>
      </EmailAutomationsContext.Provider>
      <BackTop />
    </Layout.Content>
  )
}

export default EmailAutomationHome
