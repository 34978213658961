import React from 'react'
import { Typography, notification } from 'antd'
import PropTypes from 'prop-types'
import style from './index.module.scss'
import { OnCSATPropTypes } from '../../../UserPropTypes'
import { useUser } from '../../../../../hooks/useUser'
import api from '../../../../../api'
import { transformInputChannel } from '../../../../../utils/transformInputChannel'
import getJobStatus from './getJobStatus'
import ConfirmModal from './ConfirmModal'

const CSATTab = ({ onCSAT, conversationId, channel }) => {
  const { environment } = useUser()

  const closeCSAT = async () => {
    const { data } = await api.User.closeCSAT(
      {
        conversationId,
        channel: transformInputChannel(channel),
      },
      { datasource: environment.toLowerCase() },
    )

    const jobStatus = await getJobStatus(data.jobId, environment.toLowerCase())

    if (jobStatus === 'SUCCESS') {
      notification.success({ message: '[Close CSAT] Success!' })
    } else {
      notification.error({ message: `[Close CSAT] ${jobStatus}` })
    }
  }

  return (
    <>
      <div className={style.border}>
        {onCSAT.status && (
          <>
            <div>
              Conversation ID:
              {' '}
              <Typography.Text copyable>{conversationId}</Typography.Text>
            </div>
            <div>Status: OPEN</div>
          </>
        )}
        {!onCSAT.status && (
          'No open CSAT conversations.'
        )}
      </div>
      <div className={style.pt16}>
        <ConfirmModal
          modalTitle='Confirm close CSAT conversation?'
          buttonTitle='Close CSAT conversation'
          onConfirm={closeCSAT}
          disabled={!onCSAT.status}
        />
      </div>
    </>
  )
}

CSATTab.propTypes = {
  onCSAT: OnCSATPropTypes.isRequired,
  conversationId: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
}

export default CSATTab
