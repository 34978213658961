import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { notification, Form } from 'antd'
import { UserContext } from '../../hooks/useUser'
import api from '../../api'

const openErrorNotification = (error, domain = 'UNKNOWN') => {
  notification.error({
    message: `[${domain}] ${error.message}`,
    description: error.data?.status,
  })
}

export const UserProvider = ({ children }) => {
  const [environment, setEnvironment] = useState('PRODUCTION')
  const [dataSource, setDataSource] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isInit, setIsInit] = useState(true)
  const [form] = Form.useForm()

  const getFilterFormFieldsValue = () => {
    const values = form.getFieldsValue()

    if (values.channels?.length === 1) {
      return { channel: values.channels?.[0] }
    }

    return {}
  }

  const loadDataSource = async (filter) => {
    if (!filter.unknownUsers && Object.keys(filter).length < 2) {
      notification.warning({
        message: 'Start search by input any query input',
      })

      return
    }

    setIsLoading(true)
    setIsInit(false)
    try {
      const users = await api.User.getUserList(filter, { datasource: environment.toLowerCase() })
      setDataSource(users.data)
    } catch (error) {
      openErrorNotification(error, 'GET_USER')
      setDataSource([])
    }

    setIsLoading(false)
  }

  return (
    <UserContext.Provider
      value={{ environment, setEnvironment, dataSource, setDataSource, loadDataSource, isLoading, form, getFilterFormFieldsValue, isInit }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
