import React, { useContext } from 'react'
import { Result } from 'antd'
import PropTypes from 'prop-types'
import useRequest from '@core/hooks/useRequest'

import ExpandableJsonPreview from '@core/components/ExpandableJsonPreview'
import SpinnerFillWidth from '@core/components/SpinnerFillWidth'

import Api from '../../../../api'

import { EmailAutomationsContext } from '../../../../context'

// NOTE: This is temporary solution for not blocking comparison analyzer response

const OnlyRulesBasedResponse = ({ body, countryOfEmployment }) => {
  const { selectedWorkingVersion: version, datasource } = useContext(EmailAutomationsContext)
  const [{ loading, data, hasError }] = useRequest(Api.Emails.analyzeEmailByBody, { body, countryOfEmployment }, { version, datasource })

  if (loading) return <SpinnerFillWidth />
  if (hasError) return <Result title='Something went wrong, please try again' status='error' />

  return (
    <ExpandableJsonPreview
      content={JSON.stringify(data, null, 2)}
      mode='tree'
      autoSize
    />
  )
}

OnlyRulesBasedResponse.defaultProps = {
  body: '',
  countryOfEmployment: '',
}

OnlyRulesBasedResponse.propTypes = {
  body: PropTypes.string,
  countryOfEmployment: PropTypes.string,
}

export default OnlyRulesBasedResponse
