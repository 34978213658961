/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react'
import {
  Tabs,
  Row,
  Col,
  Typography,
  Divider,
} from 'antd'
import PropTypes from 'prop-types'
import useRequest from '@core/hooks/useRequest'

import Api from '../../../../api'
import { EmailAutomationsContext } from '../../../../context'
import { PREPROCESSING_METHOD } from '../../../../constants/preprocessingMethods'

import ApiResponse from './ApiResponse'
import { RuleBasedPreprocessed, NlpPreprocessed, CheckPreprocessedStringBetweenMethods } from './PreprocessedComparison'

const AnalyzedResponse = ({ body, countryOfEmployment, activeTab, setActiveTab }) => {
  const { selectedWorkingVersion: version, datasource } = useContext(EmailAutomationsContext)

  const [ruleBasedResponse] = useRequest(
    Api.Emails.analyzeEmailByBody,
    { body, countryOfEmployment },
    { version, datasource, preprocessingMethod: PREPROCESSING_METHOD.RULE_BASED },
  )
  const [machineLearningResponse] = useRequest(
    Api.Emails.analyzeEmailByBody,
    { body, countryOfEmployment },
    { version, datasource, preprocessingMethod: PREPROCESSING_METHOD.MACHINE_LEARNING },
  )

  return (
    <Tabs
      activeKey={activeTab}
      onChange={setActiveTab}
      type='card'
      defaultActiveKey='apiResponse'
    >
      <Tabs.TabPane forceRender tab='API Response' key='apiResponse'>
        <Row gutter={[8, 0]}>
          <Col span={12}>
            <Typography.Text strong>Only Rule-based</Typography.Text>
            <Divider />
            <ApiResponse loading={ruleBasedResponse.loading} data={ruleBasedResponse?.data} hasError={ruleBasedResponse.hasError} />
          </Col>
          <Col span={12}>
            <Typography.Text strong>NLP + Rule-based</Typography.Text>
            <Divider />
            <ApiResponse loading={machineLearningResponse.loading} data={machineLearningResponse?.data} hasError={machineLearningResponse.hasError} />
          </Col>
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane forceRender tab='Preprocessed Comparison' key='preprocessComparison'>
        <CheckPreprocessedStringBetweenMethods
          ruleBasedDebug={ruleBasedResponse?.data?.__debug}
          mlPreprocessedDebug={machineLearningResponse?.data?.__debug}
        />
        <Row gutter={[8, 0]}>
          <Col span={12}>
            <Typography.Text strong>Only Rule-based</Typography.Text>
            <Divider />
            <RuleBasedPreprocessed
              loading={ruleBasedResponse.loading}
              data={ruleBasedResponse?.data}
              hasError={ruleBasedResponse.hasError}
            />
          </Col>
          <Col span={12}>
            <Typography.Text strong>NLP + Rule-based</Typography.Text>
            <Divider />
            <NlpPreprocessed
              loading={machineLearningResponse.loading}
              data={machineLearningResponse?.data}
              hasError={machineLearningResponse.hasError}
            />
          </Col>
        </Row>
      </Tabs.TabPane>
    </Tabs>
  )
}

AnalyzedResponse.defaultProps = {
  body: '',
  countryOfEmployment: '',
}

AnalyzedResponse.propTypes = {
  body: PropTypes.string,
  countryOfEmployment: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export default AnalyzedResponse
